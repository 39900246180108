import moment from 'moment';
import React from 'react';

export const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const daysOfTheWeekShortened = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const timeOfDayMap = { AM: 1, PM: 10 };

export const niceRolesFormatter = (roles, firstOnly = false, ignoreRoleUser = true) => {
  const niceRoleMap = {
    'Primary User': 'Consultant',
    Subuser: 'Consultant Sub User',
  };
  const searchRegex = new RegExp(Object.keys(niceRoleMap).join('|'), 'gi');
  const niceRoles = roles
    .filter((r) => !ignoreRoleUser || r !== 'ROLE_USER')
    .map((r) => r.replace('ROLE_', '').replace('_', ' ').toLowerCase())
    .join(', ')
    .replace(/\b\w/g, (l) => l.toUpperCase())
    .replace(searchRegex, function (matched) {
      return niceRoleMap[matched];
    });
  if (firstOnly) {
    return niceRoles.split(',')[0];
  }
  return niceRoles;
};

export const niceDateFormatter = (utcDate, format = 'DD/MM/YYYY') => {
  return moment(utcDate).format(format);
};

export const niceClinicDateFormatter = (utcDate, format = 'DD MMM YYYY') => {
  return moment(utcDate).format(format);
};

export const niceDateTimeFormatter = (utcDate, format = 'DD/MM/YYYY HH:mm') => {
  return niceDateFormatter(utcDate, format);
};

export const userNameFormatter = (userObj, includeNumberOfProcessingInvoices = false, includeEmail = false) => {
  if (typeof userObj === 'undefined' || !userObj) {
    return null;
  }
  return `${userObj.firstName} ${userObj.lastName}${includeNumberOfProcessingInvoices ? ' (Currently Processing: ' + userObj.numberOfInvoicesCurrentlyProcessing + ')' : ''}${includeEmail ? ' (' + userObj.email + ')' : ''}`;
};

export const highlightDiffFormatter = (displayValue, checkValue) => {
  // if displayValue does not match checkValue, return displayValue in a span with class 'text-danger', otherwise return displayValue as is
  if (displayValue !== checkValue) {
    return (
      <span className="text-danger font-weight-bold" title={`Changed from ${checkValue}`}>
        {displayValue}
      </span>
    );
  }
  return displayValue;
};

export const dateDiffFormatter = (utcDateFirst, utcDateSecond, format = 'days') => {
  let dateFirst = moment();
  if (utcDateFirst) {
    dateFirst = moment(utcDateFirst);
  }

  let dateSecond = moment();
  if (utcDateSecond) {
    dateSecond = moment(utcDateSecond);
  }

  return dateFirst.diff(dateSecond, 'days');
};

export const dayOfWeekFormatter = (dow) => {
  return daysOfTheWeek[dow] || '';
};

export const dayOfWeekShortenedFormatter = (dow) => {
  return daysOfTheWeekShortened[dow] || '';
};

export const timeOfDayText = (timeOfDay) => {
  return timeOfDay === timeOfDayMap.AM ? 'AM' : 'PM';
};

export const generalCurrencyFormatter = (cost, currencySymbol = '£') => {
  return currencySymbol + Number(cost).toFixed(2);

  // should probably be:
  //  return Intl.NumberFormat('en-GB', {
  //    style: 'currency',
  //    currency: 'GBP',
  //  }).format(cost)
};

export const invoiceAmountFormatter = (cost, currencySymbol) => {
  //  invoice amount and outstanding balance stored in small currency so converting to big before formatting
  return generalCurrencyFormatter(cost / 100, currencySymbol);
};

export const capitalizeWords = (strToCapitalize) => {
  return strToCapitalize
    .trim()
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

export const invoiceBatchIdFromIri = (iri) => {
  return iri ? Number(iri.replace('/invoice_batches/', '')) : false;
};

class Formatter {
  format(toFormat) {
    return toFormat.join(', ');
  }
}

export const listFormatter = (() => {
  try {
    return new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
  } catch (TypeError) {
    return new Formatter();
  }
})();

export const getSortIcon = (sortDir) => {
  return sortDir ? (
    sortDir === 'DESC' ? (
      <i className={'fa fa-sort-down pl-2'}></i>
    ) : (
      <i className={'fa fa-sort-up pl-2'}></i>
    )
  ) : (
    <i className={'fa fa-sort pl-2'}></i>
  );
};

export function camelToReadable(key) {
  return key
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Add space before uppercase letters
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
}

const stringsToConvert = {
  '(gt)': ' >',
  '(gte)': ' >=',
  '(lte)': ' <=',
  '(before)': ' <',
  '(after)': ' >',
  '(strictly_before)': ' <',
  '(strictly_after)': ' >',
};
const filtersToShorten = ['.id'];
const filtersWithoutValues = ['consultantType', 'assignedTo', 'status'];
const filtersToRemove = ['assignedUser'];
const filtersToDivide = ['outstandingBalance >=', 'outstandingBalance <='];

function convertString(inString) {
  let didConvert = false;
  Object.keys(stringsToConvert).forEach((strToConvert) => {
    if (inString.includes(strToConvert)) {
      didConvert = true;
      inString = inString.replace(strToConvert, stringsToConvert[strToConvert]);
    }
  });
  return [inString, didConvert];
}

export function filtersToReadableString(obj) {
  return Object.entries(obj)
    .map(([key, value]) => {
      let didConvert = false;
      [key, didConvert] = convertString(key);

      if (value === '' || value === undefined || value === null || filtersToRemove.some((substr) => key.includes(substr))) {
        return false;
      }
      if (filtersToShorten.some((substr) => key.includes(substr))) {
        return capitalizeWords(key.replace('.id', ''));
      }
      if (filtersWithoutValues.some((substr) => key.includes(substr))) {
        return camelToReadable(key);
      }
      if (filtersToDivide.some((substr) => key.includes(substr))) {
        return `${camelToReadable(key)} ${Number(value) / 100}`;
      }
      return `${camelToReadable(key)}${didConvert ? '' : ':'} ${['0', '1'].includes(value) && key.includes('is') ? (Boolean(Number(value)) ? 'Yes' : 'No') : value}`;
    })
    .filter((el) => el !== false)
    .join(', ');
}
