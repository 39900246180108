import React from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import { niceDateFormatter, userNameFormatter } from '../../lib/helper/formatting';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';
import CONSULTANT_TICKET_TYPE_CODES from '../../lib/helper/consultantTicketTypeCodes';

class ConsultantTicketListTable extends React.Component {
  render() {
    const { consultantTickets = [] } = this.props;
    return (
      <div>
        <Table striped hover responsive className="consultant-ticket-list-table">
          <thead>
            <tr>
              <th>Ticket Number</th>
              <th>Ticket Created</th>
              <th>Consultant</th>
              <th>Ticket Type</th>
              <th>Action Date</th>
              <th>Ticket Actioned</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {consultantTickets.map((ticket) => {
              return (
                <tr key={ticket.id}>
                  <td>
                    <Link to={ROUTES.CONSULTANT_TICKETS.SINGLE.replace(':id', ticket.id)} className="text-body text-nowrap">
                      <span className="mr-2">{ticket.id}</span>
                    </Link>
                  </td>
                  <td>{niceDateFormatter(ticket.createdAt, 'DD/MM/YYYY HH:mm')}</td>
                  <td>
                    {ticket.consultantTicketType.code === CONSULTANT_TICKET_TYPE_CODES.CONSULTANT_BROADCAST
                      ? 'All ' + (ticket.consultantType?.displayName ?? 'Consultant') + 's' + (ticket.includeSecretaries ? ' and Secretaries' : '')
                      : ticket.consultant.name}
                  </td>
                  <td>{ticket.consultantTicketType.displayName}</td>
                  <td>{ticket.actionDate ? niceDateFormatter(ticket.actionDate, 'DD/MM/YYYY') : ''}</td>
                  <td>
                    {ticket.actionedDate ? (
                      <i
                        className="mr-1 fa fa-check-circle text-primary"
                        title={`Actioned ${niceDateFormatter(ticket.actionedDate, 'DD/MM/YYYY HH:mm')}${ticket.actionedBy ? ' by ' + userNameFormatter(ticket.actionedBy) : ''}`}
                      />
                    ) : (
                      <i className="mr-1 fa fa-times-circle text-danger" title="Not Actioned" />
                    )}
                  </td>
                  <td>
                    <Link to={ROUTES.CONSULTANT_TICKETS.SINGLE.replace(':id', ticket.id)} className="btn btn-sm btn-primary ml-2">
                      View
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

ConsultantTicketListTable.propTypes = {
  consultantTickets: PropTypes.array.isRequired,
  //  selectHospital: PropTypes.func.isRequired,
};

export default ConsultantTicketListTable;
