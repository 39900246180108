import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { renderCheckbox, renderField } from '../../../lib/helper/form';

import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

const mapStateToProps = (state, props) => {
  return {
    userData: state.auth.userData,
    currentFormValues: getFormValues('ReviewInvoiceDataForm')(state),
  };
};
const mapDispatchToProps = {
  addToast,
};

class ReviewInvoiceDataForm extends React.Component {
  constructor(props) {
    super(props);

    const { initialize } = this.props;

    const initVals = {
      ...props.invoiceData,
      patientDOB: props.invoiceData?.patientDOB ? moment(props.invoiceData.patientDOB, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      invoiceDate: props.invoiceData?.invoiceDate ? moment(props.invoiceData.invoiceDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      shortfallInvoiceDate: props.invoiceData?.shortfallInvoiceDate ? moment(props.invoiceData.shortfallInvoiceDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      serviceDate: props.invoiceData?.serviceDate ? moment(props.invoiceData.serviceDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      invoiceAmount: props.invoiceData?.invoiceAmount.replace(/[£,]/g, ''),
      amountDue: props.invoiceData?.amountDue.replace(/[£,]/g, ''),
    };

    initialize(initVals);
}

  onSubmit(values) {
    const invoiceData = { ...this.props.invoiceData, ...values };
    if (
            invoiceData?.invoiceBatchConsultantId && invoiceData?.consultant?.id && invoiceData.invoiceBatchConsultantId !== invoiceData.consultant.id
    ) {
      // consultantMismatch between invoiceBatch and invoice.
      return;
    }
    // create a new object where values overwrites this.props.invoiceData
    delete invoiceData.specialist;
    delete invoiceData.userChecked;
    delete invoiceData.invoiceBatchConsultantId;
    this.props.onSubmit(invoiceData);
  }

  render() {
    const { handleSubmit, error, pristine, submitting, currentFormValues, invoiceData } = this.props;
    const consultantMismatch =
            invoiceData?.invoiceBatchConsultantId && invoiceData?.consultant?.id && invoiceData.invoiceBatchConsultantId !== invoiceData.consultant.id;
    return (
      <div className="row">
        <div className="col">
          <h4 className={'mb-3'}>Confirm Details</h4>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <p>
            Consultant: {invoiceData?.consultant?.name ?? <strong>NOT FOUND</strong>}{' '}
            {invoiceData?.consultant?.name && !invoiceData?.consultant?.useAutomatedChasing && (
              <>
                <br />
                <strong style={{ color: 'red' }}>AUTO CHASING DISABLED</strong>
              </>
            )}
          </p>
            {consultantMismatch && <div className="alert alert-danger text-center">The invoice consultant does not match this invoice batch</div>}
            <Row>
              <Col className={'col-lg-8'}>
                <Field required name="invoiceNo" label="Invoice Number" type="text" component={renderField} />
              </Col>
              <Col className={'col-lg-4'}>
                <Field
                  required
                  name="invoiceDate"
                  label={currentFormValues?.isShortfall ? 'Shortfall Date' : 'Invoice Date'}
                  type="date"
                  component={renderField}
                />
              </Col>
            </Row>
            {currentFormValues?.isShortfall ? (
              <Row>
                <Col className={'col-lg-4'}>
                  <Field required name="shortfallInvoiceDate" label={'Original Invoice Date'} type="date" component={renderField} />
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col className={'col-lg-8'}>
                <Field required name="patientName" label="Patient Name" type="text" component={renderField} />
              </Col>
              <Col className={'col-lg-4'}>
                <Field required name="patientDOB" label="Patient DOB" type="date" component={renderField} />
              </Col>
            </Row>

            <Row>
              <Col className={'col-lg-4'}>
                <Field name="serviceDate" label="Service Date" type="date" component={renderField} />
              </Col>
              <Col className={'col-lg-4'}>
                <Field required name="invoiceAmount" label="Invoice Amount (£)" type="number" step=".01" component={renderField} />
              </Col>
              <Col className={'col-lg-4'}>
                <Field
                  required
                  name="amountDue"
                  label={currentFormValues?.isShortfall ? 'Shortfall Amount (£)' : 'O/S Amount (£)'}
                  type="number"
                  step=".01"
                  component={renderField}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field name="subscriberNumber" label="Subscriber Number (Insurer Reg.)" type={'text'} component={renderField} />
              </Col>
              <Col>
                <Field name="claimNumber" label="Claim Number" type={'text'} component={renderField} />
              </Col>
            </Row>

            <Row>
              <Col className={'col-lg-4'}>
                <Field name="isShortfall" label="Shortfall?" id="is-shortfall" component={renderCheckbox} />{' '}
              </Col>
              <Col>
                <Field
                  required
                  name="userChecked"
                  label="I confirm I've checked these details"
                  id="user-checked"
                  component={renderCheckbox}
                  className={'text-right'}
                />
              </Col>
            </Row>

            <hr />
            {!invoiceData.existingInvoiceId && !consultantMismatch && !!invoiceData?.consultant?.id ? (
              <button type="submit" disabled={pristine || submitting || !currentFormValues.userChecked} className="btn btn-primary float-right">
                Lookup Contact Details
              </button>
            ) : (
              <Alert variant={'danger'} className={'text-center'}>
                You cannot upload this invoice{!!invoiceData?.invoiceBatchId ? ' against this batch' : ''}.
              </Alert>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'ReviewInvoiceDataForm',
  })(ReviewInvoiceDataForm),
);
