import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import RouteWithLayout from './components/routing/RouteWithLayout';

import {
  Dashboard,
  BillingChecklist,
  BillingChecklistEventList,
  BillingChecklistEventSingle,
  BillingChecklistEventCreate,
  WorkSchedule,
  WorkScheduleEventList,
  WorkScheduleEventSingle,
  WorkScheduleEventCreate,
  ConsultantList,
  ConsultantSingle,
  ConsultantSingleForSubUser,
  ConsultantSingleCreate,
  ConsultantTicketList,
  ConsultantTicketSingle,
  ConsultantTicketCreate,
  ConsultantBroadcastList,
  ConsultantBroadcastCreate,
  HospitalList,
  EmbassyInvoiceBatchList,
  InvoiceBatchList,
  InvoiceBatchSingle,
  InvoiceBatchCreate,
  ReportsList,
  ReportBatchCreate,
  InvoiceList,
  InvoiceUpdatedInsuranceDetailsList,
  InvoiceParse,
  InvoiceNotificationEventList,
  UserList,
  UserSingle,
  UserSingleCreate,
  Profile,
  SelectConsultant,
  SubAccountAccept,
  ExportInvoiceNumbers,
  Blank,
  //  KitchenSink,
  NotFound,
  Denied,
  Help,
  Terms,
  CcsdCodeList,
  CcsdCodeSingle,
  CmsContent,
  InvoiceImportCSV,
  InvoiceImportPaidCSV,
  ChaserWorkSchedule,
  InvoiceSingle,
  SiteSettings,
} from './pages';

import LoginForm from './components/forms/Auth/LoginForm';
import ForgottenPasswordForm from './components/forms/Auth/ForgottenPasswordForm';
import ResetPasswordForm from './components/forms/Auth/ResetPasswordForm';
import TwoFactorAuthForm from './components/forms/Auth/TwoFactorAuthForm';

import { default as MinimalLayout } from './components/layout/MinimalLayout';
import { default as StandardLayout } from './components/layout/StandardLayout';

import * as ROUTES from './lib/routing/frontend';
import * as API_ROUTES from './lib/routing/api';
import INVOICE_BATCH_STATUS from './lib/helper/statusCodes';
import ProblemDataCreateContainer from './pages/ProblemDataSingleCreate';
import ProblemDataListContainer from './pages/ProblemDataList';
import ProblemDataEditContainer from './pages/ProblemDataSingle';
import PROBLEM_STATUS from './lib/helper/problemStatusCodes';
import { PAYOR_TYPES } from './lib/helper/invoice';
import SupportPage from './pages/Support';
import { isLoggedInUser } from './lib/helper/authorisation';
import PayAnInvoiceContainer from './pages/PublicPatientInvoice/PayAnInvoiceContainer';
import ViewAnInvoiceContainer from './pages/PublicPatientInvoice/ViewAnInvoiceContainer';
import PaymentResponseContainer from './pages/PublicPatientInvoice/PaymentResponseContainer';
import UpdateInsuranceDetailsContainer from './pages/PublicInsuranceDetails/UpdateInsuranceDetailsContainer';
import InsuranceDetailsSuccess from './pages/PublicInsuranceDetails/InsuranceDetailsSuccess';
import CONSULTANT_TICKET_TYPE_CODES from './lib/helper/consultantTicketTypeCodes';

const Routes = ({ userId }) => {
  return (
      <Switch>
        <RouteWithLayout exact path={ROUTES.LOGIN} layout={MinimalLayout} component={LoginForm} secure={false} pageTitle={'Login'}/>
        <RouteWithLayout
            exact
            path={ROUTES.FORGOT_PASSWORD}
            layout={MinimalLayout}
            component={ForgottenPasswordForm}
            secure={false}
            pageTitle={'Forgot Password'}
        />
        <RouteWithLayout exact path={ROUTES.RESET_PASSWORD} layout={MinimalLayout} component={ResetPasswordForm} secure={false} pageTitle={'Reset Password'}/>
        <RouteWithLayout exact path={ROUTES.AUTH2FA} layout={MinimalLayout} component={TwoFactorAuthForm} secure={false} pageTitle={'Authenticate'}/>
        <RouteWithLayout exact path={ROUTES.HOME} layout={StandardLayout} component={Dashboard} pageTitle={'Dashboard'}/>
        <RouteWithLayout exact path={ROUTES.BILLING_CHECKLIST} layout={StandardLayout} component={BillingChecklist} pageTitle={'Billing Checklist'}/>
        <RouteWithLayout
            exact
            path={ROUTES.BILLING_CHECKLIST_EVENTS.ALL}
            layout={StandardLayout}
            component={BillingChecklistEventList}
            pageTitle={'Billing Checklist Events'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.BILLING_CHECKLIST_EVENTS.SINGLE}
            layout={StandardLayout}
            component={BillingChecklistEventSingle}
            pageTitle={'A Billing Checklist Event'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.BILLING_CHECKLIST_EVENTS.CREATE}
            layout={StandardLayout}
            component={BillingChecklistEventCreate}
            pageTitle={'Create a Billing Checklist Event'}
        />
        <RouteWithLayout exact path={ROUTES.WORK_SCHEDULE} layout={StandardLayout} component={WorkSchedule} pageTitle={'Batch Work Schedule'}/>
        <RouteWithLayout
            exact
            path={ROUTES.WORK_SCHEDULE_EVENTS.ALL}
            layout={StandardLayout}
            component={WorkScheduleEventList}
            pageTitle={'Outsourcer Holidays'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.WORK_SCHEDULE_EVENTS.SINGLE}
            layout={StandardLayout}
            component={WorkScheduleEventSingle}
            pageTitle={'An Outsourcer Holiday'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.WORK_SCHEDULE_EVENTS.CREATE}
            layout={StandardLayout}
            component={WorkScheduleEventCreate}
            pageTitle={'Create an Outsourcer Holiday'}
        />
        <RouteWithLayout exact path={ROUTES.CHASER_WORK_SCHEDULE} layout={StandardLayout} component={ChaserWorkSchedule} pageTitle={'Chaser Work Schedule'}/>
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_BATCHES.ALL}
            layout={StandardLayout}
            component={InvoiceBatchList}
            defaultFilters={{ 'order[id]': 'DESC' }}
            pageTitle={'All Invoice Batches'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_BATCHES.EMBASSY}
            layout={StandardLayout}
            component={EmbassyInvoiceBatchList}
            defaultFilters={{
              isEmbassy: true,
              'status.code': [String(INVOICE_BATCH_STATUS.SUBMITTED), String(INVOICE_BATCH_STATUS.PROCESSING), String(INVOICE_BATCH_STATUS.CHECKED_AND_PRICED)],
            }}
            pageTitle={'Embassy Invoice Batches'}
            filterOverrides={{ isEmbassy: false, assignedUser: false, dateRange: false, invoiceDates: true, status: true }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_BATCHES.URGENT}
            layout={StandardLayout}
            component={InvoiceBatchList}
            showUrgentCategory={true}
            defaultFilters={{
              'status.code': [
                String(INVOICE_BATCH_STATUS.SUBMITTED),
                String(INVOICE_BATCH_STATUS.PROCESSING),
                String(INVOICE_BATCH_STATUS.CHECKED_AND_PRICED),
                String(INVOICE_BATCH_STATUS.COMPLETED),
              ],
              isUrgent: true,
            }}
            pageTitle={'Urgent Invoice Batches'}
            filterOverrides={{ isUrgent: false, urgentCategory: true }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_BATCHES.UNASSIGNED}
            layout={StandardLayout}
            component={InvoiceBatchList}
            defaultFilters={{ 'status.code': [String(INVOICE_BATCH_STATUS.SUBMITTED), String(INVOICE_BATCH_STATUS.CHECKED_AND_PRICED)] }}
            pageTitle={'Unassigned Invoice Batches'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_BATCHES.PROBLEM}
            layout={StandardLayout}
            component={InvoiceBatchList}
            showProblemCategory={true}
            defaultFilters={{
              'status.code': [String(INVOICE_BATCH_STATUS.SUBMITTED), String(INVOICE_BATCH_STATUS.PROCESSING), String(INVOICE_BATCH_STATUS.CHECKED_AND_PRICED)],
              isProblem: true,
              'order[invoiceDates.date]': 'ASC',
            }}
            pageTitle={'Problem Batches'}
            filterOverrides={{ isProblem: false, problemCategory: true }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_BATCHES.TO_CHECK_AND_PRICE}
            layout={StandardLayout}
            component={InvoiceBatchList}
            defaultFilters={{ 'status.code': String(INVOICE_BATCH_STATUS.SUBMITTED), 'order[isUrgent]': 'DESC', 'order[createdAt]': 'ASC' }}
            pageTitle={'To Check and Price Invoice Batches'}
            filterOverrides={{ status: false }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_BATCHES.BULK_ASSIGN}
            layout={StandardLayout}
            component={InvoiceBatchList}
            showBulkSelect={true}
            enableBulkAssign={true}
            defaultFilters={{ 'status.code': [String(INVOICE_BATCH_STATUS.CHECKED_AND_PRICED)], isProblem: false, 'order[id]': 'ASC' }}
            pageTitle={'Bulk Assign Invoice Batches'}
            filterOverrides={{ status: false, isProblem: false }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_BATCHES.TO_PROCESS}
            layout={StandardLayout}
            component={InvoiceBatchList}
            defaultFilters={{ 'status.code': String(INVOICE_BATCH_STATUS.PROCESSING), assignedUser: `/users/${userId}` }}
            pageTitle={'Invoices Batches to Process'}
            filterOverrides={{ status: false }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_BATCHES.TO_ACCEPT}
            layout={StandardLayout}
            component={InvoiceBatchList}
            defaultFilters={{ 'status.code': String(INVOICE_BATCH_STATUS.CHECKED_AND_PRICED), assignedUser: `/users/${userId}` }}
            pageTitle={'Invoices Batches to Accept'}
            filterOverrides={{ status: false, assignedUser: false }}
        />
        <RouteWithLayout exact path={ROUTES.INVOICE_BATCHES.SINGLE} layout={StandardLayout} component={InvoiceBatchSingle} pageTitle={'An Invoice Batch'}/>
        <RouteWithLayout
            exact
            path={ROUTES.PROBLEM_DATA.ALL}
            layout={StandardLayout}
            component={ProblemDataListContainer}
            defaultFilters={{
              'problemStatus.code': [String(PROBLEM_STATUS.DRAFT), String(PROBLEM_STATUS.IN_PROCESSING)],
              'order[problemType.name]': 'ASC',
              'order[invoiceBatch.consultant.primaryUser.lastName]': 'ASC',
              'order[createdAt]': 'ASC',
            }}
            pageTitle={'Problem Medicode Invoice Batches'}
            filterOverrides={{ 'problemStatus.code': false }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.PROBLEM_DATA.COMPLETED}
            layout={StandardLayout}
            component={ProblemDataListContainer}
            defaultFilters={{
              'problemStatus.code': [String(PROBLEM_STATUS.COMPLETE)],
              'order[invoiceBatch.consultant.primaryUser.lastName]': 'ASC',
              'order[problemStatus.name]': 'ASC',
              'order[createdAt]': 'ASC',
            }}
            pageTitle={'Problem Medicode Invoice Batches'}
            filterOverrides={{ 'problemStatus.code': [String(PROBLEM_STATUS.COMPLETE)] }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.PROBLEM_DATA.SINGLE}
            layout={StandardLayout}
            component={ProblemDataCreateContainer}
            pageTitle={'Create Problem Data for Invoice Batches'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.PROBLEM_DATA.SINGLE_EDIT}
            layout={StandardLayout}
            component={ProblemDataEditContainer}
            pageTitle={'Edit Problem Data for Invoice Batches'}
        />
        <RouteWithLayout exact path={ROUTES.INVOICE_BATCHES.CREATE} layout={StandardLayout} component={InvoiceBatchCreate} pageTitle={'Upload Invoice Batches'}/>
        <RouteWithLayout exact path={ROUTES.REPORTS.CREATE} layout={StandardLayout} component={ReportBatchCreate} pageTitle={'Upload Reports'}/>
        <RouteWithLayout
            exact
            path={ROUTES.INVOICES.BULK_ASSIGN}
            layout={StandardLayout}
            component={InvoiceList}
            showBulkSelect={true}
            enableBulkAssign={true}
            pageType={'bulk'}
            defaultFilters={{
              isPaid: false,
              isEscalated: false,
              isVoid: false,
              isWrittenOff: false,
              isMissingContactDetails: false,
              'payorType.code': Number(PAYOR_TYPES.PATIENT),
              'exists(assignedTo)': false,
              itemsPerPage: 500,
              'order[dateOfLastContact]': 'ASC',
              'order[consultant.code]': 'ASC',
              'order[patientLastName]': 'ASC',
            }}
            pageTitle={'Bulk Assign Credit Control Invoices'}
            filterOverrides={{ escalationFilters: true }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICE_NOTIFICATION_EVENTS.ALL}
            layout={StandardLayout}
            component={InvoiceNotificationEventList}
            pageType={'all'}
            defaultFilters={{}}
            pageTitle={'Failed Invoice Notifications'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICES.ALL}
            layout={StandardLayout}
            component={InvoiceList}
            pageType={'all'}
            defaultFilters={{
              isPaid: false,
              'payorType.code': Number(PAYOR_TYPES.PATIENT),

              'order[invoiceDate]': 'DESC',
              'order[consultant.code]': 'ASC',
              'order[patientLastName]': 'ASC',
            }}
            pageTitle={'Credit Control Invoices'}
            filterOverrides={{ escalationFilters: true, isVoid: true }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICES.WITH_UPDATED_INSURANCE_DETAILS}
            layout={StandardLayout}
            component={InvoiceUpdatedInsuranceDetailsList}
            pageType={'all'}
            defaultFilters={{
//              isPaid: false,
              'payorType.code': Number(PAYOR_TYPES.PATIENT),
              'invoiceInsuranceDetails[exists]': 'true',
              'invoiceInsuranceDetails.actionedDate[exists]': 'false'
            }}
            filterOverrides={{ escalationFilters: false, isVoid: false, isPaid: false, isWrittenOff: false, isMissingContactDetails: false, isEscalated: false, osBal: false, assignToUser: false, id: false }}
            pageTitle={'Invoices with Insurance Details Updates'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICES.MY_ASSIGNED}
            layout={StandardLayout}
            component={InvoiceList}
            pageType={'all'}
            defaultFilters={{
              isPaid: false,
              'payorType.code': Number(PAYOR_TYPES.PATIENT),
              assignedTo: `/users/${userId}`,
              'order[invoiceDate]': 'DESC',
              'order[consultant.code]': 'ASC',
              'order[patientLastName]': 'ASC',
            }}
            pageTitle={'Credit Control Invoices'}
            filterOverrides={{ escalationFilters: true, isVoid: true }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICES.VOIDS}
            layout={StandardLayout}
            component={InvoiceList}
            pageType={'all'}
            defaultFilters={{
              isVoid: true,
              'payorType.code': Number(PAYOR_TYPES.PATIENT),

              'order[invoiceDate]': 'DESC',
              'order[consultant.code]': 'ASC',
              'order[patientLastName]': 'ASC',
            }}
            filterOverrides={{ isPaid: false }}
            pageTitle={'Void Invoices'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICES.UNPAID}
            layout={StandardLayout}
            component={InvoiceList}
            pageType={'all'}
            defaultFilters={{
              isPaid: false,
              isVoid: false,
              'payorType.code': Number(PAYOR_TYPES.PATIENT),
              'order[invoiceDate]': 'DESC',
              'order[consultant.code]': 'ASC',
              'order[patientLastName]': 'ASC',
              isWrittenOff: false,
            }}
            pageTitle={'Unpaid Invoices'}
            filterOverrides={{ isPaid: false, isWrittenOff: false }}
        />
        <RouteWithLayout exact path={ROUTES.INVOICES.SINGLE} layout={StandardLayout} component={InvoiceSingle}/>
        <RouteWithLayout
            exact
            path={ROUTES.INVOICES.ESCALATED}
            layout={StandardLayout}
            component={InvoiceList}
            pageType={'escalation'}
            defaultFilters={{
              isPaid: '',
              isVoid: false,
              isEscalated: true,
              'payorType.code': Number(PAYOR_TYPES.PATIENT),
              'outstandingBalance(gt)': 0,
              'order[dateOfLastContact]': 'ASC',
              'order[consultant.code]': 'ASC',
              'order[patientLastName]': 'ASC',
            }}
            pageTitle={'Credit Control Invoices - Escalated'}
            filterOverrides={{ isEscalated: false, escalationFilters: true }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.INVOICES.MISSING_CONTACT_DETAILS}
            layout={StandardLayout}
            component={InvoiceList}
            pageType={'missing'}
            defaultFilters={{
              isPaid: '',
              isMissingContactDetails: true,
              'payorType.code': Number(PAYOR_TYPES.PATIENT),
              'outstandingBalance(gt)': 0,
              'order[dateOfLastContact]': 'ASC',
              'order[consultant.code]': 'ASC',
              'order[patientLastName]': 'ASC',
            }}
            pageTitle={'Credit Control Invoices - Missing Contact Details'}
            filterOverrides={{ isMissingContactDetails: false }}
        />
        <RouteWithLayout
            exact
            path={ROUTES.REPORTS.ALL}
            layout={StandardLayout}
            component={ReportsList}
            defaultFilters={{ isPublished: true, 'order[id]': 'DESC' }}
            pageTitle={'All Reports'}
            redirectBack={true}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CONSULTANTS.ALL}
            layout={StandardLayout}
            component={ConsultantList}
            defaultFilters={{ 'order[createdAt]': 'DESC' }}
            pageTitle={'Consultants'}
        />
        <RouteWithLayout exact path={ROUTES.CONSULTANTS.SINGLE} layout={StandardLayout} component={ConsultantSingle} pageTitle={'An Consultant'}/>
        <RouteWithLayout
            exact
            path={ROUTES.CONSULTANTS.SINGLE_FOR_SUBUSER}
            layout={StandardLayout}
            component={ConsultantSingleForSubUser}
            pageTitle={'The Consultant'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CONSULTANTS.CREATE}
            layout={StandardLayout}
            component={ConsultantSingleCreate}
            pageTitle={'Add a New Consultant Account'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.SUBACCOUNT.ACCEPT_SECURE}
            layout={MinimalLayout}
            component={SubAccountAccept}
            pageTitle={'You have been invited to join MediACCOUNTS'}
            secure={true}
            redirectBack={true}
        />
        <RouteWithLayout
            exact
            path={ROUTES.SUBACCOUNT.ACCEPT}
            layout={MinimalLayout}
            component={SubAccountAccept}
            pageTitle={'You have been invited to join MediACCOUNTS'}
            secure={false}
        />
        <RouteWithLayout
            exact
            path={ROUTES.HOSPITALS.ALL}
            layout={StandardLayout}
            defaultFilters={{ 'order[name]': 'ASC' }}
            component={HospitalList}
            pageTitle={'Hospitals'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CONSULTANT_TICKETS.SINGLE}
            layout={StandardLayout}
            //            defaultFilters={{ 'order[name]': 'ASC' }}
            component={ConsultantTicketSingle}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CONSULTANT_TICKETS.ALL_TICKETS}
            layout={StandardLayout}
            defaultFilters={{ 'exists[actionedDate]': false }} // unactioned
            component={ConsultantTicketList}
            pageTitle={'Consultant Tickets'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CONSULTANT_TICKETS.CREATE}
            layout={StandardLayout}
            component={ConsultantTicketCreate}
            pageTitle={'Create a Consultant Ticket'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CONSULTANT_TICKETS.ALL_BROADCASTS}
            layout={StandardLayout}
            defaultFilters={{ 'exists[actionedDate]': false, 'consultantTicketType.code': CONSULTANT_TICKET_TYPE_CODES.CONSULTANT_BROADCAST  }} // unactioned
            component={ConsultantBroadcastList}
            pageTitle={'Consultant Broadcasts'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CONSULTANT_TICKETS.BROADCAST}
            layout={StandardLayout}
            component={ConsultantBroadcastCreate}
            pageTitle={'Create a Consultant Broadcast'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.USERS.ALL}
            layout={StandardLayout}
            defaultFilters={{ 'order[createdAt]': 'DESC' }}
            component={UserList}
            pageTitle={'Users'}
        />
        <RouteWithLayout exact path={ROUTES.USERS.SINGLE} layout={StandardLayout} component={UserSingle} pageTitle={'A User'}/>
        <RouteWithLayout exact path={ROUTES.USERS.CREATE} layout={StandardLayout} component={UserSingleCreate} pageTitle={'Add a New User'}/>
        <RouteWithLayout exact path={ROUTES.PROFILE} layout={StandardLayout} component={Profile} pageTitle={'Edit your Profile'}/>
        <RouteWithLayout
            exact
            path={ROUTES.EXPORT_INVOICE_NUMBERS}
            layout={StandardLayout}
            component={ExportInvoiceNumbers}
            pageTitle={'Export Invoice Numbers'}
        />
        <RouteWithLayout exact path={ROUTES.SELECT_CONSULTANT} layout={MinimalLayout} component={SelectConsultant} pageTitle={'Choose an Account'}/>
        <RouteWithLayout exact path={ROUTES.CCSD_CODES.ALL} layout={StandardLayout} component={CcsdCodeList} pageTitle={'CCSD Codes'}/>
        <RouteWithLayout exact path={ROUTES.CCSD_CODES.SINGLE} layout={StandardLayout} component={CcsdCodeSingle} pageTitle={'A CCSD Code'}/>
        <RouteWithLayout
            exact
            path={ROUTES.CMS.REMITTANCES}
            apiRoute={API_ROUTES.CMS_FETCH_REMITTANCES}
            layout={StandardLayout}
            component={CmsContent}
            pageTitle={'Remittances'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CMS.HOSPITAL}
            apiRoute={API_ROUTES.CMS_FETCH_HOSPITAL}
            layout={StandardLayout}
            component={CmsContent}
            pageTitle={'Hospital'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CMS.INSURANCE}
            apiRoute={API_ROUTES.CMS_FETCH_INSURANCE}
            layout={StandardLayout}
            component={CmsContent}
            pageTitle={'Insurance'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.CMS.EMBASSY}
            apiRoute={API_ROUTES.CMS_FETCH_EMBASSY}
            layout={StandardLayout}
            component={CmsContent}
            pageTitle={'Embassy'}
        />
        <RouteWithLayout exact path={ROUTES.SITE_SETTINGS} layout={StandardLayout} component={SiteSettings} pageTitle={'Portal Settings'}/>

        {/*#CC*/}
        <RouteWithLayout exact path={ROUTES.INVOICES.CREATE} layout={StandardLayout} component={InvoiceImportCSV} pageTitle={'Upload Invoice CSV'}/>
        <RouteWithLayout exact path={ROUTES.INVOICES.UPDATE} layout={StandardLayout} component={InvoiceImportPaidCSV} pageTitle={'Upload Paid Invoice CSV'}/>
        <RouteWithLayout exact path={ROUTES.INVOICES.PARSE} layout={StandardLayout} component={InvoiceParse} pageTitle={'Upload Invoice File'}/>
        <RouteWithLayout exact path={ROUTES.TERMS} layout={MinimalLayout} component={Terms} pageTitle={'Terms and Conditions'}/>
        <RouteWithLayout exact path={ROUTES.BLANK} layout={StandardLayout} component={Blank} secure={false}/>
        <RouteWithLayout exact path={ROUTES.NOT_FOUND} layout={MinimalLayout} component={NotFound} secure={false} pageTitle={'Error 404'}/>
        <RouteWithLayout exact path={ROUTES.DENIED} layout={MinimalLayout} component={Denied} secure={false} pageTitle={'Access Denied'}/>
        {/*{process.env.NODE_ENV !== 'production' &&*/}
        {/*    <RouteWithLayout*/}
        {/*        exact*/}
        {/*        path={ROUTES.KITCHEN_SINK}*/}
        {/*        layout={StandardLayout}*/}
        {/*        component={KitchenSink}*/}
        {/*        secure={false}*/}
        {/*    />}*/}
        <RouteWithLayout exact path={ROUTES.HELP} layout={StandardLayout} component={Help} secure={false} pageTitle={'FAQs / Help'}/>

        <RouteWithLayout exact path={ROUTES.INVOICES.PARSE} layout={StandardLayout} component={InvoiceParse} secure={false} pageTitle={'Upload Invoice'}/>
        <RouteWithLayout
            exact
            path={ROUTES.PUBLIC_PATIENT_INVOICES.VIEW}
            layout={MinimalLayout}
            linkLogo={false}
            component={ViewAnInvoiceContainer}
            secure={false}
            pageTitle={'View an Invoice'}
        />

        <RouteWithLayout
            exact
            path={ROUTES.PUBLIC_PATIENT_INVOICES.PAY}
            layout={MinimalLayout}
            linkLogo={false}
            component={PayAnInvoiceContainer}
            secure={false}
            pageTitle={'View an Invoice'}
        />

        <RouteWithLayout
            exact
            path={ROUTES.PUBLIC_INSURANCE_DETAILS.VIEW}
            layout={MinimalLayout}
            linkLogo={false}
            component={UpdateInsuranceDetailsContainer}
            secure={false}
            pageTitle={'Update Insurance Details'}
        />

        <RouteWithLayout
            exact
            path={ROUTES.PUBLIC_INSURANCE_DETAILS.SUCCESS}
            layout={MinimalLayout}
            linkLogo={false}
            component={InsuranceDetailsSuccess}
            secure={false}
            pageTitle={'Update Insurance Details'}
        />

        <RouteWithLayout
            exact
            path={ROUTES.PUBLIC_PATIENT_INVOICES.PAYMENT_RESPONSE}
            layout={MinimalLayout}
            linkLogo={false}
            component={PaymentResponseContainer}
            secure={false}
            pageTitle={'Payment'}
        />
        <RouteWithLayout
            exact
            path={ROUTES.SUPPORT}
            layout={isLoggedInUser(userId) ? StandardLayout : MinimalLayout}
            component={SupportPage}
            secure={false}
            pageTitle="Support"
        />
        <Redirect to={ROUTES.NOT_FOUND}/>
      </Switch>
  );
};

export default Routes;
