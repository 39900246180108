import React from 'react';
import Form from 'react-bootstrap/Form';
import MASpinner from '../../common/MASpinner';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { consultantBroadcastCreate } from '../../../lib/redux/actions/consultantTickets';
import { renderCheckbox,  renderField, renderSelect } from '../../../lib/helper/form';
import { addToast } from '../../../lib/redux/actions/toast';
import { consultantTypeListFetch } from '../../../lib/redux/actions/consultantType';

const mapStateToProps = (state) => {
  return {
    ...state.consultantTypes,
    ...state.consultantTicketTypes,
  };
};
const mapDispatchToProps = {
  consultantTypeListFetch,
  consultantBroadcastCreate,
  addToast,
};

class CreateConsultantBroadcastForm extends React.Component {
  componentDidMount() {
    const { consultantTypeListFetch } = this.props;
    consultantTypeListFetch();
  }

  onSubmit(values) {
    const { consultantBroadcastCreate, reset, addToast } = this.props;

    return consultantBroadcastCreate({
      message: values.message,
      consultantType: values.consultantType,
      includeSecretaries: values.includeSecretaries ?? false,
    }).then(() => {
      addToast('The Broadcast was created', true);
      reset();
    });
  }

  render() {
    const {
      handleSubmit,
      error,
      pristine,
      submitting,
      isCreatingConsultantTicket,
      isFetchingConsultantTypes,
      consultantTypes = [],
    } = this.props;

    if (isFetchingConsultantTypes) {
      return <MASpinner/>;
    }
    const typeEveryone = { text: 'All Consultants and Anaesthetists', value: 'all' };
    const typeOptions = consultantTypes.map((consultantType) => ({
      value: consultantType['@id'],
      text: `All ${consultantType.displayName}s`,
    }));
    typeOptions.push(typeEveryone);

    return (
        <div className="invoice-batch-single__section mb-3">
          <div className="invoice-batch-single__section-icon">
            <i className="fas fa-edit"/>
          </div>

          <h2 className="invoice-batch-single__section-header">Create Broadcast</h2>
          <p>
            Broadcast a message to all active Clients (and optionally their secretaries)
          </p>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <div className={'row mb-4'}>
              <div className={'col-sm-3'}>
                <Field name="consultantType" options={typeOptions} emptyLabel={'Please select'} label="Broadcast To" component={renderSelect} required={true}/>
                <Field
                    wrapperClassNames="checkbox"
                    name="includeSecretaries"
                    label="Include Secretaries"
                    id={`includeSecretaries`}
                    component={renderCheckbox}
                />
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-sm-12'}>
                <Field className="mb-2" style={{ minHeight: '200px' }} name="message" label="Message" id="message" type="textarea" required={true} component={renderField}/>
                {isCreatingConsultantTicket ? (
                    <MASpinner/>
                ) : (
                    <button type="submit" disabled={pristine || submitting} className="btn btn-primary btn-block mb-2">
                      Create Broadcast
                    </button>
                )}
              </div>
            </div>
          </Form>
        </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
      form: 'CreateConsultantBroadcastForm',
      enableReinitialize: true,
    })(CreateConsultantBroadcastForm),
);
