import React, { Component, Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { niceDateFormatter, userNameFormatter } from '../../lib/helper/formatting';
import CreateConsultantTicketNoteForm from '../../components/forms/ConsultantTicket/CreateConsultantTicketNoteForm';
import MarkAsActionedButton from '../../components/consultantTicket/MarkAsActionedButton';
import Badge from 'react-bootstrap/Badge';
import EmailAuditLogList from '../../components/emailAuditLog/EmailAuditLogList';
import CONSULTANT_TICKET_TYPE_CODES from '../../lib/helper/consultantTicketTypeCodes';
import { isClient, isStaff } from '../../lib/helper/authorisation';
import { consultantTicketNoteSnipAt } from '../../lib/redux/actions/consultantTicketNotes';
import { connect } from 'react-redux';
import HoverableText from '../../components/InvoiceBatch/HoverableText';
import Alert from 'react-bootstrap/Alert';

const mapDispatchToProps = {
  consultantTicketNoteSnipAt,
};

class ConsultantTicketNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snipAtIndex: props.consultantTicketNote?.snipAtIndex ?? false,
      enableSnip: false,
    };
  }

  render() {
    const { consultantTicket, consultantTicketNote } = this.props;

    const handleLineClick = ({ index }) => {
      this.setState({ snipAtIndex: index, enableSnip: false });
      this.props.consultantTicketNoteSnipAt(index, consultantTicketNote.id);
    };

    return (
      <div>
        {!isClient() && (
          <span className="action-icon ml-2" onClick={() => this.setState({ enableSnip: !this.state.enableSnip })}>
            <span className="sr-only">Shorten note text</span>
            {!this.state.enableSnip ? <i className="fas fa-cut" title="Shorten note text" /> : <i className="fas fa-undo" title="Stop shortening note text" />}
          </span>
        )}
        <p>
          <strong>{userNameFormatter(consultantTicketNote.createdBy)} replied:</strong>
        </p>

        <HoverableText
          classes="consultant-ticket-single__note-content font-italic"
          note={consultantTicketNote}
          onLineClick={handleLineClick}
          snipAtIndex={this.state.snipAtIndex}
          enableSnip={this.state.enableSnip}
        />

        {consultantTicket.consultantTicketType.code !== CONSULTANT_TICKET_TYPE_CODES.CONSULTANT_BROADCAST ? (
          <EmailAuditLogList id={consultantTicketNote.id} entityType="ConsultantTicketNote" />
        ) : null}
      </div>
    );
  }
}

const ConsultantTicketNoteConnected = connect(null, mapDispatchToProps)(ConsultantTicketNote);

class ConsultantTicketSingle extends Component {
  render() {
    const { consultantTicket, onNoteCreate } = this.props;

    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header className={'card-header--crud'}>
                <div className="d-flex align-items-center">
                  <span className="consultant-ticket-single__meta">
                    <span className="d-none d-sm-inline">Consultant Ticket ID</span>:{' '}
                    <strong className="consultant-ticket-single__id">{consultantTicket.id}</strong>
                  </span>

                  <span className="ml-auto consultant-ticket-single__statuses">
                    {consultantTicket?.actionedDate ? (
                      <Badge
                        pill
                        variant={'primary'}
                        className="ml-2"
                        title={`${niceDateFormatter(consultantTicket.actionedBy)} by ${userNameFormatter(consultantTicket.actionedBy)}`}>
                        <strong className="consultant-ticket-single__id text-uppercase">Actioned</strong>
                      </Badge>
                    ) : (
                      <Badge pill variant={'light'} className="ml-2">
                        <strong className="consultant-ticket-single__id text-uppercase">Unactioned</strong>
                      </Badge>
                    )}
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    {consultantTicket.consultantTicketType.code === CONSULTANT_TICKET_TYPE_CODES.CONSULTANT_BROADCAST ? (
                      <h1 className="consultant-ticket-single__consultant-name">
                        Broadcast to:{' '}
                        {`All ${consultantTicket.consultantType?.displayName ?? 'Consultant'}s ${consultantTicket.includeSecretaries ? ' and Secretaries' : ''}`}
                      </h1>
                    ) : (
                      <h1 className="consultant-ticket-single__consultant-name">
                        {consultantTicket.consultant.name}
                        <span className="consultant-ticket-single__consultant-id ml-3">{consultantTicket.consultant.code}</span>
                        {consultantTicket.consultant.gmcReferenceNumber && (
                          <span className="consultant-ticket-single__consultant-id ml-3">GMC: {consultantTicket.consultant.gmcReferenceNumber}</span>
                        )}
                      </h1>
                    )}

                    <h2 className="consultant-ticket-single__date">
                      Created {niceDateFormatter(consultantTicket.createdAt, 'DD/MM/YYYY HH:mm')}{' '}
                      {consultantTicket.createdBy && `by ${userNameFormatter(consultantTicket.createdBy)}`}
                    </h2>
                    <h2 className="consultant-ticket-single__date">
                      Ticket Type: <strong>{consultantTicket.consultantTicketType.displayName}</strong>
                    </h2>
                    {consultantTicket.actionDate ? (
                      <h2 className="consultant-ticket-single__date">Action Date: {niceDateFormatter(consultantTicket.createdAt, 'DD/MM/YYYY')}</h2>
                    ) : null}

                    <hr />

                    <div className="crud__section mb-3">
                      <div className="invoice-batch-single__section-icon">
                        <i className="fas fa-ticket-alt" />
                      </div>
                      <h4 className="pl-3 pb-2">
                        {consultantTicket.consultantTicketType.code === CONSULTANT_TICKET_TYPE_CODES.CONSULTANT_BROADCAST ? 'Broadcast' : 'Ticket'} Details:
                      </h4>
                      <p className="consultant-ticket-single__note-content font-italic">
                        {consultantTicket &&
                          consultantTicket?.message &&
                          consultantTicket?.message.split('\n').map((item, key) => {
                            return (
                              <Fragment key={key}>
                                {item}
                                <br />
                              </Fragment>
                            );
                          })}
                      </p>
                      {isStaff() || consultantTicket.consultantTicketType.code !== CONSULTANT_TICKET_TYPE_CODES.CONSULTANT_BROADCAST ? (
                        <EmailAuditLogList id={consultantTicket.id} entityType="ConsultantTicket" />
                      ) : null}
                    </div>
                    {consultantTicket?.consultantTicketNotes?.length >= 1 ? (
                      consultantTicket?.consultantTicketNotes.map((note) => (
                        <Fragment key={note.id}>
                          <hr />
                          <ConsultantTicketNoteConnected consultantTicketNote={note} consultantTicket={consultantTicket} />
                        </Fragment>
                      ))
                    ) : isClient() && consultantTicket.consultantTicketType.code === CONSULTANT_TICKET_TYPE_CODES.CONSULTANT_BROADCAST ? (
                      <Alert variant={'info'}><em>Replies to this broadcast are private.</em></Alert>
                    ) : null}
                    <hr />
                    {isStaff() && consultantTicket.consultantTicketType.code === CONSULTANT_TICKET_TYPE_CODES.CONSULTANT_BROADCAST ? null : (
                      <CreateConsultantTicketNoteForm consultantTicket={consultantTicket} onCreate={onNoteCreate} />
                    )}
                    <p className={'text-right'}>
                      <MarkAsActionedButton consultantTicket={consultantTicket} />
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConsultantTicketSingle;
