import React from 'react';
import ConsultantTicketListTable from './ConsultantTicketListTable';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { consultantTicketListFetch, consultantTicketListSetPage } from '../../lib/redux/actions/consultantTickets';
import Alert from 'react-bootstrap/Alert';
import Paginator from '../../components/common/Paginator';
import * as ROUTES from '../../lib/routing/frontend';
import MASpinner from '../../components/common/MASpinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConsultantTicketTableFilterForm from '../../components/forms/ConsultantTicket/ConsultantTicketTableFilterForm';
import { isClient } from '../../lib/helper/authorisation';

const mapStateToProps = (state) => ({
  ...state.consultantTickets,
});

const mapDispatchToProps = {
  consultantTicketListFetch,
  consultantTicketListSetPage,
};

class ConsultantTicketListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
    };
  }

  getCombinedFilters() {
    const { filters, defaultFilters = {} } = this.props;
    if ( !isClient() ) {
      defaultFilters['consultantTicketType.code'] = ["BNK","FEE","HOL","OTH","FCF","INS"];
    }
    return { ...defaultFilters, ...filters };
  }

  componentDidMount() {
    this.props.consultantTicketListFetch(this.getQueryParamPage(), this.getCombinedFilters());
  }

  componentDidUpdate(prevProps) {
    const { currentPage, consultantTicketListFetch, consultantTicketListSetPage, filters } = this.props;

    if (prevProps?.match && prevProps.match.params.page !== this.getQueryParamPage()) {
      consultantTicketListSetPage(this.getQueryParamPage());
    }
    if (prevProps.currentPage !== currentPage || prevProps.filters !== filters) {
      consultantTicketListFetch(currentPage, this.getCombinedFilters());
    }
  }

  getQueryParamPage() {
    return this.props?.match?.params?.page ? Number(this.props.match.params.page) : 1;
  }

  changePage(page) {
    const { history, consultantTicketListSetPage, pushToHistory = true } = this.props;
    consultantTicketListSetPage(page);
    if (pushToHistory) {
      history.push(ROUTES.CONSULTANT_TICKETS.ALL_TICKETS.replace(':page', page));
    }
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);
    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  toggleFilters() {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  }

  render() {
    const { consultantTickets, isFetchingConsultantTickets, currentPage, pageCount, pageTitle, consultant = false } = this.props;
    return (
      <div>
        <Card className={`${consultant !== false ? 'no-shadow' : ''}`}>
          <Card.Header>
            <div className="d-flex justify-content-between">
              {' '}
              {pageTitle}
              {!isFetchingConsultantTickets && consultantTickets && consultantTickets.length > 0 && (
                <div>
                  <button className="btn btn-sm btn-outline-secondary mr-3" onClick={this.toggleFilters.bind(this)}>
                    Filters
                  </button>
                  <span className="tabular-data-result-count">{pageCount} pages</span>
                </div>
              )}
            </div>
          </Card.Header>

          {this.state.showFilters && (
            <Card.Body className={'mediaccount-filter-wrapper'}>
              <ConsultantTicketTableFilterForm consultant={consultant} />
            </Card.Body>
          )}

          {isFetchingConsultantTickets && (
            <Card.Body>
              <MASpinner />
            </Card.Body>
          )}

          {!isFetchingConsultantTickets && (consultantTickets === null || consultantTickets.length === 0) && (
            <Card.Body>
              <Alert variant={'info'} className="mb-0">
                No tickets found
              </Alert>
            </Card.Body>
          )}
          {!isFetchingConsultantTickets && consultantTickets && consultantTickets.length > 0 && (
            <Row>
              <Col sm={12}>
                <Row>
                  <Col>
                    <ConsultantTicketListTable consultantTickets={consultantTickets} />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {!isFetchingConsultantTickets && consultantTickets && consultantTickets.length > 0 && (
            <Paginator
              currentPage={currentPage}
              pageCount={pageCount}
              setPage={this.changePage.bind(this)}
              nextPage={this.nextPage.bind(this)}
              prevPage={this.prevPage.bind(this)}
            />
          )}
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantTicketListContainer);
