import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  CONSULTANT_TICKET_NOTE_SINGLE_CREATE_SUCCESS,
  CONSULTANT_TICKET_NOTE_SINGLE_CREATE_REQUEST,
  CONSULTANT_TICKET_NOTE_SINGLE_CREATE_ERROR,  CONSULTANT_TICKET_NOTE_SINGLE_UPDATED,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const consultantTicketNoteSingleCreateRequest = () => ({
  type: CONSULTANT_TICKET_NOTE_SINGLE_CREATE_REQUEST,
});

export const consultantTicketNoteSingleCreateSuccess = (data) => ({
  type: CONSULTANT_TICKET_NOTE_SINGLE_CREATE_SUCCESS,
  data,
});

export const consultantTicketNoteSingleCreateError = (error) => ({
  type: CONSULTANT_TICKET_NOTE_SINGLE_CREATE_ERROR,
  error,
});


export const consultantTicketNoteUpdated = (data) => ({
  type: CONSULTANT_TICKET_NOTE_SINGLE_UPDATED,
  data,
});

export const consultantTicketNoteSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(consultantTicketNoteSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.CONSULTANT_TICKET_NOTES}`, data)
      .then((response) => {
        dispatch(consultantTicketNoteSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const consultantTicketNoteSnipAt = (snipAt, consultantTicketNoteId) => {
  return (dispatch) => {
    return requests
        .patch(`${API_ROUTES.CONSULTANT_TICKET_NOTES}/${consultantTicketNoteId}`, {snipAtIndex: snipAt})
        .then((response) => dispatch(consultantTicketNoteUpdated(response)))
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            return dispatch(userLogout());
          } else if (error.response && error.response.status === 403) {
            return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
          }
          throw new SubmissionError(parseApiErrors(error));
        });
  };
};
