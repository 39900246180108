import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConsultantBroadcastCreate from './ConsultantBroadcastCreate';
import { consultantSingleFetch, consultantSingleFetchByIRI, consultantSingleUnload } from '../../lib/redux/actions/consultant';
import { addToast } from '../../lib/redux/actions/toast';
import { getSubusersConsultantToManage, isConsultant, isSubUser } from '../../lib/helper/authorisation';

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.consultantSingle,
});

const mapDispatchToProps = {
  consultantSingleFetchByIRI,
  consultantSingleFetch,
  consultantSingleUnload,
  addToast,
};
class ConsultantBroadcastCreateContainer extends Component {
  constructor(props) {
    super(props);
    if (isSubUser(true) && getSubusersConsultantToManage() && getSubusersConsultantToManage().iri) {
      this.props.consultantSingleFetchByIRI(getSubusersConsultantToManage().iri);
    }
    if (isConsultant(true) && this.props.userData && this.props.userData.consultantAsPrimaryUser && this.props.userData.consultantAsPrimaryUser['@id']) {
      this.props.consultantSingleFetchByIRI(this.props.userData.consultantAsPrimaryUser['@id']);
    }
  }
  componentWillUnmount() {
    this.props.consultantSingleUnload();
  }

  render() {
    const { consultant } = this.props;
    return <ConsultantBroadcastCreate consultant={consultant ?? undefined} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantBroadcastCreateContainer);
