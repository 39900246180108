import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SubMenu } from 'react-pro-sidebar';
import * as ROUTES from '../../lib/routing/frontend';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  canUploadInvoices,
  isAdmin,
  isChaserOutsourcer,
  isConsultant,
  isOutsourcer,
  isSeniorStaff,
  isStaff,
  isSubUser,
  subUserCanUploadBills,
  subUserCanViewAndDownloadInvoices,
  subUserCanViewAndDownloadReports,
} from '../../lib/helper/authorisation';
import { invoiceListClearFilters } from '../../lib/redux/actions/invoices';
import { problemDataListClearFilters } from '../../lib/redux/actions/problemData';
import { invoiceBatchListClearFilters } from '../../lib/redux/actions/invoiceBatch';
import { consultantListClearFilters } from '../../lib/redux/actions/consultant';
import { userListClearFilters } from '../../lib/redux/actions/userList';
import { ccsdCodeListClearFilters } from '../../lib/redux/actions/ccsdCodes';

const Link = (props) => <RRLink {...props} />;

const mapStateToProps = (state) => ({
  ...state.stats,
  ...state.auth,
  ...state.subUserSingle,
});

const mapDispatchToProps = {
  invoiceListClearFilters,
  invoiceBatchListClearFilters,
  problemDataListClearFilters,
  consultantListClearFilters,
  userListClearFilters,
  ccsdCodeListClearFilters,
};

class Sidebar extends React.Component {
  getWrappedLink(linkTo, clickEvent = () => {
  }) {
    const { toggleSidebar } = this.props;
    return (
        <Link
            to={linkTo}
            onClick={() => {
              clickEvent();
              toggleSidebar();
            }}
        />
    );
  }

  renderAdminMenu() {
    const { stats, invoiceListClearFilters, invoiceBatchListClearFilters, problemDataListClearFilters, consultantListClearFilters, userListClearFilters, ccsdCodeListClearFilters } = this.props;
    return (
        <>
          <SubMenu title="Billing Checklist" icon={<i className="fas fa-calendar-check"></i>}>
            <MenuItem icon={<i className="fas fa-calendar-check"/>}>
              Billing Checklist{this.getWrappedLink(ROUTES.BILLING_CHECKLIST.replace('/:date', ''))}
            </MenuItem>
            <MenuItem icon={<i className="fas fa-calendar-check"/>}>
              View Events/Holidays{this.getWrappedLink(ROUTES.BILLING_CHECKLIST_EVENTS.ALL.replace('/:page', ''))}
            </MenuItem>
            <MenuItem icon={<i className="fas fa-calendar-check"/>}>Create Event/Holiday{this.getWrappedLink(ROUTES.BILLING_CHECKLIST_EVENTS.CREATE)}</MenuItem>
          </SubMenu>
          <SubMenu title="Outsourcer Scheduling" icon={<i className="fas fa-calendar"></i>}>
            <MenuItem icon={<i className="fas fa-calendar-check"/>}>Outsourcers{this.getWrappedLink(ROUTES.WORK_SCHEDULE.replace('/:date', ''))}</MenuItem>
            <MenuItem icon={<i className="fas fa-calendar-check"/>}>
              View all Holidays{this.getWrappedLink(ROUTES.WORK_SCHEDULE_EVENTS.ALL.replace('/:page', ''))}
            </MenuItem>
            <MenuItem icon={<i className="fas fa-calendar-check"/>}>Add a Holiday{this.getWrappedLink(ROUTES.WORK_SCHEDULE_EVENTS.CREATE)}</MenuItem>
          </SubMenu>

          <MenuItem
              icon={<i className="fas fa-inbox"/>}
              suffix={stats.invoicesToAccept && stats.invoicesToAccept > 0 ? <span className="badge badge-pill badge-danger">{stats.invoicesToAccept}</span> : ''}>
            Mine to Accept{this.getWrappedLink(ROUTES.INVOICE_BATCHES.TO_ACCEPT.replace('/:page', ''))}
          </MenuItem>
          <MenuItem
              icon={<i className="fas fa-spinner"/>}
              suffix={
                stats.invoicesToProcess && stats.invoicesToProcess > 0 ? <span className="badge badge-pill badge-danger">{stats.invoicesToProcess}</span> : ''
              }>
            Mine to Process{this.getWrappedLink(ROUTES.INVOICE_BATCHES.TO_PROCESS.replace('/:page', ''))}
          </MenuItem>
          <MenuItem
              icon={<i className="fas fa-exclamation-circle text-danger"/>}
              suffix={
                stats.problemInvoiceBatches && stats.problemInvoiceBatches > 0 ? (
                    <span className="badge badge-pill badge-danger">{stats.problemInvoiceBatches}</span>
                ) : (
                    ''
                )
              }>
            Problem Batches{this.getWrappedLink(ROUTES.INVOICE_BATCHES.PROBLEM.replace('/:page', ''), () => {
            invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.PROBLEM);
          })}
          </MenuItem>
          <SubMenu title="Invoice Management" icon={<i className="fas fa-list"/>}>
            <MenuItem
                icon={<i className="fas fa-clock text-warning"/>}
                suffix={
                  stats.urgentInvoiceBatchesToComplete && stats.urgentInvoiceBatchesToComplete > 0 ? (
                      <span className="badge badge-pill badge-danger">{stats.urgentInvoiceBatchesToComplete}</span>
                  ) : (
                      ''
                  )
                }>
              Urgent Invoice Batches{this.getWrappedLink(ROUTES.INVOICE_BATCHES.URGENT.replace('/:page', ''), () => {
              invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.URGENT);
            })}
            </MenuItem>
            <MenuItem
                icon={<i className="fas fa-check-circle"/>}
                suffix={
                  stats.invoicesToCheckAndPrice && stats.invoicesToCheckAndPrice > 0 ? (
                      <span className="badge badge-pill badge-danger">{stats.invoicesToCheckAndPrice}</span>
                  ) : (
                      ''
                  )
                }>
              To Check and Price{this.getWrappedLink(ROUTES.INVOICE_BATCHES.TO_CHECK_AND_PRICE.replace('/:page', ''), () => {
              invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.TO_CHECK_AND_PRICE);
            })}
            </MenuItem>
            <MenuItem icon={<i className="fas fa-check-circle"/>}>
              Bulk Assign Invoices{this.getWrappedLink(ROUTES.INVOICE_BATCHES.BULK_ASSIGN.replace('/:page', ''), () => {
              invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.BULK_ASSIGN);
            })}
            </MenuItem>
            <MenuItem
                icon={<i className="fas fa-question-circle"/>}
                suffix={
                  stats.invoicesToAssign && stats.invoicesToAssign > 0 ? <span className="badge badge-pill badge-danger">{stats.invoicesToAssign}</span> : ''
                }>
              Unassigned Invoice Batches{this.getWrappedLink(ROUTES.INVOICE_BATCHES.UNASSIGNED.replace('/:page', ''), () => {
              invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.UNASSIGNED);
            })}
            </MenuItem>
            <MenuItem icon={<i className="fas fa-list"/>}>All Invoice Batches{this.getWrappedLink(ROUTES.INVOICE_BATCHES.ALL.replace('/:page', ''), () => {
              invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.ALL);
            })}</MenuItem>
            <MenuItem icon={<i className="fas fa-upload"/>}>Upload Invoices{this.getWrappedLink(ROUTES.INVOICE_BATCHES.CREATE)}</MenuItem>
            <MenuItem icon={<i className="fas fa-download"/>}>Export Invoice Numbers{this.getWrappedLink(ROUTES.EXPORT_INVOICE_NUMBERS)}</MenuItem>
          </SubMenu>

          <SubMenu title="Credit Control" icon={<i className="fas fa-file-invoice"/>}>
            <MenuItem>UnPaid Invoices{this.getWrappedLink(
                ROUTES.INVOICES.UNPAID.replace('/:page', ''),
                () => invoiceListClearFilters(ROUTES.INVOICES.UNPAID),
            )}</MenuItem>
            <MenuItem>All Invoices{this.getWrappedLink(
                ROUTES.INVOICES.ALL.replace('/:page', ''),
                () => invoiceListClearFilters(ROUTES.INVOICES.ALL),
            )}</MenuItem>
            <MenuItem>Void Invoices{this.getWrappedLink(ROUTES.INVOICES.VOIDS.replace('/:page', ''), () => {
              invoiceListClearFilters(ROUTES.INVOICES.VOIDS);
            })}</MenuItem>
            <MenuItem
                suffix={
                  stats.escalatedCreditControlInvoices && stats.escalatedCreditControlInvoices > 0 ? (
                      <span className="badge badge-pill badge-danger">{stats.escalatedCreditControlInvoices}</span>
                  ) : (
                      ''
                  )
                }>
              Escalated Invoices{this.getWrappedLink(ROUTES.INVOICES.ESCALATED.replace('/:page', ''), () => {
              invoiceListClearFilters(ROUTES.INVOICES.ESCALATED);
            })}
            </MenuItem>
            <MenuItem
                suffix={
                  stats.creditControlInvoicesMissingContactDetails && stats.creditControlInvoicesMissingContactDetails > 0 ? (
                      <span className="badge badge-pill badge-danger">{stats.creditControlInvoicesMissingContactDetails}</span>
                  ) : (
                      ''
                  )
                }>
              Missing Contact Details{this.getWrappedLink(ROUTES.INVOICES.MISSING_CONTACT_DETAILS.replace('/:page', ''), () => {
              invoiceListClearFilters(ROUTES.INVOICES.MISSING_CONTACT_DETAILS);
            })}
            </MenuItem>
            <MenuItem
                suffix={
                  stats.creditControlInvoicesUnassigned && stats.creditControlInvoicesUnassigned > 0 ? (
                      <span className="badge badge-pill badge-danger">{stats.creditControlInvoicesUnassigned}</span>
                  ) : (
                      ''
                  )
                }>
              Bulk Assign Work{this.getWrappedLink(ROUTES.INVOICES.BULK_ASSIGN.replace('/:page', ''), () => {
              invoiceListClearFilters(ROUTES.INVOICES.BULK_ASSIGN);
            })}
            </MenuItem>
            <MenuItem>Chaser Work Schedules{this.getWrappedLink(ROUTES.CHASER_WORK_SCHEDULE.replace('/:date', ''))}</MenuItem>
            <MenuItem>Upload New CSV{this.getWrappedLink(ROUTES.INVOICES.CREATE)}</MenuItem>
            <MenuItem>Upload Paid Invoices CSV{this.getWrappedLink(ROUTES.INVOICES.UPDATE)}</MenuItem>
            <MenuItem>Upload Shortfall/Rebill {this.getWrappedLink(ROUTES.INVOICES.PARSE)}</MenuItem>
            <MenuItem>Failed Invoice Notifications {this.getWrappedLink(ROUTES.INVOICE_NOTIFICATION_EVENTS.ALL.replace('/:page', ''))}</MenuItem>
            <MenuItem
                suffix={
                  stats.insUpd && stats.insUpd > 0 ? (
                      <span className="badge badge-pill badge-danger">{stats.insUpd}</span>
                  ) : (
                      ''
                  )
                }
            >Insurance Details Updated {this.getWrappedLink(ROUTES.INVOICES.WITH_UPDATED_INSURANCE_DETAILS.replace('/:page', ''))}</MenuItem>
          </SubMenu>

          <SubMenu title="Extended Workflow" icon={<i className="fas fa-columns"></i>}>
            <MenuItem icon={<i className="fas fa-clock text-warning"/>}>
              Embassy Invoice Batches{this.getWrappedLink(ROUTES.INVOICE_BATCHES.EMBASSY.replace('/:page', ''), () => {
              invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.EMBASSY);
            })}
            </MenuItem>
            <MenuItem icon={<i className="fas fa-clock text-warning"/>}>
              Problem Medicode Invoices{this.getWrappedLink(ROUTES.PROBLEM_DATA.ALL.replace('/:page', ''), () => {
              problemDataListClearFilters(ROUTES.PROBLEM_DATA.ALL);
            })}
            </MenuItem>
            <MenuItem icon={<i className="fas fa-clock text-warning"/>}>
              Complete Problem Invoices{this.getWrappedLink(ROUTES.PROBLEM_DATA.COMPLETED.replace('/:page', ''), () => {
              problemDataListClearFilters(ROUTES.PROBLEM_DATA.COMPLETED);
            })}
            </MenuItem>
          </SubMenu>

          <SubMenu title="Reports" icon={<i className="fas fa-envelope"/>}>
            <MenuItem>View all Reports{this.getWrappedLink(ROUTES.REPORTS.ALL.replace('/:page', ''))}</MenuItem>
            <MenuItem>Upload Reports{this.getWrappedLink(ROUTES.REPORTS.CREATE)}</MenuItem>
          </SubMenu>
          <SubMenu title="Accounts" icon={<i className="fas fa-user"/>}>
            <MenuItem>View Consultants{this.getWrappedLink(ROUTES.CONSULTANTS.ALL.replace('/:page', ''), () => {
              consultantListClearFilters(ROUTES.CONSULTANTS.ALL);
            })}</MenuItem>
            <MenuItem>Create a Consultant Account{this.getWrappedLink(ROUTES.CONSULTANTS.CREATE)}</MenuItem>
            <MenuItem>View Users{this.getWrappedLink(ROUTES.USERS.ALL.replace('/:page', ''), () => {
              userListClearFilters(ROUTES.USERS.ALL);
            })}</MenuItem>
            <MenuItem>Create a New User{this.getWrappedLink(ROUTES.USERS.CREATE)}</MenuItem>
            <MenuItem>View Hospitals{this.getWrappedLink(ROUTES.HOSPITALS.ALL.replace('/:page', ''))}</MenuItem>
          </SubMenu>
          <SubMenu
              title="Consultant Tickets"
              icon={<i className="fas fa-ticket-alt"/>}
              suffix={
                stats.unactionedTickets && stats.unactionedTickets > 0 ? <span className="badge badge-pill badge-danger">{stats.unactionedTickets}</span> : ''
              }>
            <MenuItem>Consultant Tickets{this.getWrappedLink(ROUTES.CONSULTANT_TICKETS.ALL_TICKETS.replace('/:page', ''))}</MenuItem>
            <MenuItem>Create a Consultant Ticket{this.getWrappedLink(ROUTES.CONSULTANT_TICKETS.CREATE)}</MenuItem>
            <li><hr className={'my-1 mx-3'}/></li>
            <MenuItem>Consultant Broadcasts{this.getWrappedLink(ROUTES.CONSULTANT_TICKETS.ALL_BROADCASTS.replace('/:page', ''))}</MenuItem>
            <MenuItem>Create a Consultant Broadcast{this.getWrappedLink(ROUTES.CONSULTANT_TICKETS.BROADCAST)}</MenuItem>
          </SubMenu>
          <SubMenu title="MediCodes" icon={<i className="fas fa-book-medical"/>}>
            <MenuItem>CCSD codes{this.getWrappedLink(ROUTES.CCSD_CODES.ALL.replace('/:page', ''), () => {
              ccsdCodeListClearFilters(ROUTES.CCSD_CODES.ALL);
            })}</MenuItem>
          </SubMenu>
          <SubMenu title="Info" icon={<i className="fas fa-info"/>}>
            <MenuItem>Remittances{this.getWrappedLink(ROUTES.CMS.REMITTANCES)}</MenuItem>
            <MenuItem>Hospital{this.getWrappedLink(ROUTES.CMS.HOSPITAL)}</MenuItem>
            <MenuItem>Insurance{this.getWrappedLink(ROUTES.CMS.INSURANCE)}</MenuItem>
            <MenuItem>Embassy{this.getWrappedLink(ROUTES.CMS.EMBASSY)}</MenuItem>
          </SubMenu>
        </>
    );
  }

  renderStaffMenu() {
    const { stats, invoiceBatchListClearFilters, invoiceListClearFilters, consultantListClearFilters } = this.props;
    return (
        <>
          <MenuItem
              icon={<i className="fas fa-clock text-warning"/>}
              suffix={
                stats.urgentInvoiceBatchesToComplete && stats.urgentInvoiceBatchesToComplete > 0 ? (
                    <span className="badge badge-pill badge-danger">{stats.urgentInvoiceBatchesToComplete}</span>
                ) : (
                    ''
                )
              }>
            Urgent Invoice Batches{this.getWrappedLink(ROUTES.INVOICE_BATCHES.URGENT.replace('/:page', ''), () => {
            invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.URGENT);
          })}
          </MenuItem>
          <MenuItem
              icon={<i className="fas fa-inbox"/>}
              suffix={stats.invoicesToAccept && stats.invoicesToAccept > 0 ? <span className="badge badge-pill badge-danger">{stats.invoicesToAccept}</span> : ''}>
            My Invoices to Accept{this.getWrappedLink(ROUTES.INVOICE_BATCHES.TO_ACCEPT.replace('/:page', ''))}
          </MenuItem>
          <MenuItem
              icon={<i className="fas fa-spinner"/>}
              suffix={
                stats.invoicesToProcess && stats.invoicesToProcess > 0 ? <span className="badge badge-pill badge-danger">{stats.invoicesToProcess}</span> : ''
              }>
            My Invoices to Process{this.getWrappedLink(ROUTES.INVOICE_BATCHES.TO_PROCESS.replace('/:page', ''))}
          </MenuItem>
          <MenuItem icon={<i className="fas fa-list"/>}>All Invoice Batches{this.getWrappedLink(ROUTES.INVOICE_BATCHES.ALL.replace('/:page', ''), () => {
            invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.ALL);
          })}</MenuItem>
          <MenuItem icon={<i className="fas fa-upload"/>}>Upload Invoices{this.getWrappedLink(ROUTES.INVOICE_BATCHES.CREATE)}</MenuItem>

          <SubMenu title="Credit Control" icon={<i className="fas fa-file-invoice"/>}>
            <MenuItem>UnPaid Invoices{this.getWrappedLink(ROUTES.INVOICES.UNPAID.replace('/:page', ''), () => {
              invoiceListClearFilters(ROUTES.INVOICES.UNPAID);
            })}</MenuItem>
            <MenuItem
                suffix={
                  stats.invoicesCurrentlyAssigned && stats.invoicesCurrentlyAssigned > 0 ? (
                      <span className="badge badge-pill badge-danger">{stats.invoicesCurrentlyAssigned}</span>
                  ) : (
                      ''
                  )
                }
                icon={<i className="fas fa-file-invoice"/>}>
              CC Invoices{this.getWrappedLink(ROUTES.INVOICES.MY_ASSIGNED.replace('/:page', ''), () => {
              invoiceListClearFilters(ROUTES.INVOICES.MY_ASSIGNED);
            })}
            </MenuItem>
            <MenuItem
                suffix={
                  stats.escalatedCreditControlInvoices && stats.escalatedCreditControlInvoices > 0 ? (
                      <span className="badge badge-pill badge-danger">{stats.escalatedCreditControlInvoices}</span>
                  ) : (
                      ''
                  )
                }>
              Escalated Invoices{this.getWrappedLink(ROUTES.INVOICES.ESCALATED.replace('/:page', ''), () => {
              invoiceListClearFilters(ROUTES.INVOICES.ESCALATED);
            })}
            </MenuItem>
            <MenuItem
                suffix={
                  stats.creditControlInvoicesMissingContactDetails && stats.creditControlInvoicesMissingContactDetails > 0 ? (
                      <span className="badge badge-pill badge-danger">{stats.creditControlInvoicesMissingContactDetails}</span>
                  ) : (
                      ''
                  )
                }>
              Missing Contact Details{this.getWrappedLink(ROUTES.INVOICES.MISSING_CONTACT_DETAILS.replace('/:page', ''), () => {
              invoiceListClearFilters(ROUTES.INVOICES.MISSING_CONTACT_DETAILS);
            })}
            </MenuItem>
          </SubMenu>
          {/*<SubMenu title="Accounts" icon={<i className='fas fa-user'/>}>*/}
          <MenuItem icon={<i className="fas fa-user-md"/>}>View Consultants{this.getWrappedLink(ROUTES.CONSULTANTS.ALL.replace('/:page', ''), () => {
            consultantListClearFilters(ROUTES.CONSULTANTS.ALL);
          })}</MenuItem>
          {/*<MenuItem>View Users{this.getWrappedLink(ROUTES.USERS.ALL.replace('/:page', ''))}</MenuItem>*/}
          {/*</SubMenu>*/}

          <SubMenu title="Info" icon={<i className="fas fa-info"/>}>
            <MenuItem>Remittances{this.getWrappedLink(ROUTES.CMS.REMITTANCES)}</MenuItem>
            <MenuItem>Hospital{this.getWrappedLink(ROUTES.CMS.HOSPITAL)}</MenuItem>
            <MenuItem>Insurance{this.getWrappedLink(ROUTES.CMS.INSURANCE)}</MenuItem>
            <MenuItem>Embassy{this.getWrappedLink(ROUTES.CMS.EMBASSY)}</MenuItem>
          </SubMenu>
        </>
    );
  }

  renderOutsourcerMenu() {
    const { stats, invoiceBatchListClearFilters, invoiceListClearFilters } = this.props;
    return (
        <>
          <SubMenu title="Work Schedule" icon={<i className="fas fa-calendar-check"></i>}>
            {isOutsourcer(true) && (
                <MenuItem icon={<i className="fas fa-calendar-check"/>}>
                  Batch Work Schedule{this.getWrappedLink(ROUTES.WORK_SCHEDULE.replace('/:date', ''))}
                </MenuItem>
            )}
            {isChaserOutsourcer(true) && (
                <MenuItem icon={<i className="fas fa-calendar-check"/>}>
                  Invoice Work Schedule{this.getWrappedLink(ROUTES.CHASER_WORK_SCHEDULE.replace('/:date', ''))}
                </MenuItem>
            )}
            {isOutsourcer(true) && (
                <MenuItem icon={<i className="fas fa-calendar-check"/>}>
                  View my Holidays{this.getWrappedLink(ROUTES.WORK_SCHEDULE_EVENTS.ALL.replace('/:page', ''))}
                </MenuItem>
            )}
            {isOutsourcer(true) && (
                <MenuItem icon={<i className="fas fa-calendar-check"/>}>Add a Holiday{this.getWrappedLink(ROUTES.WORK_SCHEDULE_EVENTS.CREATE)}</MenuItem>
            )}
          </SubMenu>

          {isOutsourcer(true) && (
              <SubMenu defaultOpen={true} title="Invoice Batches" icon={<i className="fas fa-calendar-check"></i>}>
                <MenuItem
                    icon={<i className="fas fa-inbox"/>}
                    suffix={
                      stats.invoicesToAccept && stats.invoicesToAccept > 0 ? <span className="badge badge-pill badge-danger">{stats.invoicesToAccept}</span> : ''
                    }>
                  Batches to Accept{this.getWrappedLink(ROUTES.INVOICE_BATCHES.TO_ACCEPT.replace('/:page', ''), () => {
                  invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.TO_ACCEPT);
                })}
                </MenuItem>
                <MenuItem
                    icon={<i className="fas fa-spinner"/>}
                    suffix={
                      stats.invoicesToProcess && stats.invoicesToProcess > 0 ? <span className="badge badge-pill badge-danger">{stats.invoicesToProcess}</span> : ''
                    }>
                  Batches to Process{this.getWrappedLink(ROUTES.INVOICE_BATCHES.TO_PROCESS.replace('/:page', ''), () => {
                  invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.TO_PROCESS);
                })}
                </MenuItem>
                <MenuItem icon={<i className="fas fa-list"/>}>All Invoice Batches{this.getWrappedLink(ROUTES.INVOICE_BATCHES.ALL.replace('/:page', ''), () => {
                  invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.ALL);
                })}</MenuItem>
              </SubMenu>
          )}

          {isChaserOutsourcer(true) && (
              <SubMenu defaultOpen={true} title="Invoices" icon={<i className="fas fa-calendar-check"></i>}>
                <MenuItem
                    suffix={
                      stats.invoicesCurrentlyAssigned && stats.invoicesCurrentlyAssigned > 0 ? (
                          <span className="badge badge-pill badge-danger">{stats.invoicesCurrentlyAssigned}</span>
                      ) : (
                          ''
                      )
                    }
                    icon={<i className="fas fa-file-invoice"/>}>
                  Assigned Invoices {this.getWrappedLink(ROUTES.INVOICES.MY_ASSIGNED.replace('/:page', ''), () => {
                  invoiceListClearFilters(ROUTES.INVOICES.MY_ASSIGNED);
                })}
                </MenuItem>
                {canUploadInvoices() && (
                    <MenuItem>Upload Shortfall/Rebill {this.getWrappedLink(ROUTES.INVOICES.PARSE)}</MenuItem>
                )}
              </SubMenu>
          )}


          {isOutsourcer(true) && (
              <>
                <SubMenu title="Info" icon={<i className="fas fa-info"/>}>
                  <MenuItem>Insurance{this.getWrappedLink(ROUTES.CMS.INSURANCE)}</MenuItem>
                </SubMenu>
              </>
          )}
        </>
    );
  }

  renderConsultantMenu() {
    const { userData, invoiceBatchListClearFilters } = this.props;
    return (
        <>
          <MenuItem icon={<i className="fas fa-list"/>}>All Invoices{this.getWrappedLink(ROUTES.INVOICE_BATCHES.ALL.replace('/:page', ''), () => {
            invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.ALL);
          })}</MenuItem>
          <MenuItem icon={<i className="fas fa-upload"/>}>Upload Invoices{this.getWrappedLink(ROUTES.INVOICE_BATCHES.CREATE)}</MenuItem>
          <MenuItem icon={<i className="fas fa-envelope"/>}>View My Reports{this.getWrappedLink(ROUTES.REPORTS.ALL.replace('/:page', ''))}</MenuItem>
          <MenuItem icon={<i className="fas fa-ticket-alt"/>}>
            View My Tickets{this.getWrappedLink(ROUTES.CONSULTANT_TICKETS.ALL_TICKETS.replace('/:page', ''))}
          </MenuItem>
          <MenuItem icon={<i className="fas fa-plus"/>}>Create a Ticket{this.getWrappedLink(ROUTES.CONSULTANT_TICKETS.CREATE)}</MenuItem>
          {userData && userData.consultantAsPrimaryUser && (
              <MenuItem icon={<i className="fas fa-user-cog"/>}>
                Settings and Users{this.getWrappedLink(ROUTES.CONSULTANTS.SINGLE.replace(':id', userData.consultantAsPrimaryUser.id))}
              </MenuItem>
          )}
        </>
    );
  }

  renderSubUserMenu() {
    const { invoiceBatchListClearFilters } = this.props;
    return (
        <>
          {subUserCanViewAndDownloadInvoices() ? (
              <MenuItem icon={<i className="fas fa-list"/>}>All Invoices{this.getWrappedLink(ROUTES.INVOICE_BATCHES.ALL.replace('/:page', ''), () => {
                invoiceBatchListClearFilters(ROUTES.INVOICE_BATCHES.ALL);
              })}</MenuItem>
          ) : (
              <></>
          )}
          {subUserCanUploadBills() ? (
              <MenuItem icon={<i className="fas fa-upload"/>}>Upload Invoices{this.getWrappedLink(ROUTES.INVOICE_BATCHES.CREATE)}</MenuItem>
          ) : (
              <></>
          )}
          {subUserCanViewAndDownloadReports() ? (
              <MenuItem icon={<i className="fas fa-envelope"/>}>View Reports{this.getWrappedLink(ROUTES.REPORTS.ALL.replace('/:page', ''))}</MenuItem>
          ) : (
              <></>
          )}
          <MenuItem icon={<i className="fas fa-user-md"/>}>Consultant Details{this.getWrappedLink(ROUTES.CONSULTANTS.SINGLE_FOR_SUBUSER)}</MenuItem>
          <MenuItem icon={<i className="fas fa-ticket-alt"/>}>
            Consultant Tickets{this.getWrappedLink(ROUTES.CONSULTANT_TICKETS.ALL_TICKETS.replace('/:page', ''))}
          </MenuItem>
          <MenuItem icon={<i className="fas fa-plus"/>}>Create a Ticket{this.getWrappedLink(ROUTES.CONSULTANT_TICKETS.CREATE)}</MenuItem>
        </>
    );
  }

  render() {
    const { userLogout, shouldBeVisible, stats, userData } = this.props;
    const sidebarClasses = clsx('mediaaccounts-sidebar d-print-none', {
      show: shouldBeVisible,
    });
    return (
        <ProSidebar className={sidebarClasses}>
          <SidebarContent>
            <Menu iconShape="circle">
              <MenuItem icon={<i className="fas fa-home"/>}>Dashboard{this.getWrappedLink(ROUTES.HOME)}</MenuItem>

              {process.env.NODE_ENV !== 'production' && isConsultant(true) && !userData.consultantAsPrimaryUser ? (
                  <>
                    <MenuItem icon={<i className="fas fa-sign-out-alt"/>}>User has no consultant</MenuItem>
                    <MenuItem icon={<i className="fas fa-sign-out-alt"/>} onClick={userLogout}>
                      Log Out
                    </MenuItem>
                  </>
              ) : (
                  <>
                    {(isAdmin() || isSeniorStaff(true)) && this.renderAdminMenu()}
                    {isStaff(true) && this.renderStaffMenu()}
                    {(isOutsourcer(true) || isChaserOutsourcer(true)) && this.renderOutsourcerMenu()}
                    {isConsultant(true) && this.renderConsultantMenu()}
                    {isSubUser(true) && this.renderSubUserMenu()}

                    <MenuItem icon={<i className="fas fa-user-cog"/>}>Edit Profile{this.getWrappedLink(ROUTES.PROFILE)}</MenuItem>
                    <MenuItem icon={<i className="fas fa-question"/>}>Help?{this.getWrappedLink(ROUTES.SUPPORT)}</MenuItem>
                    <MenuItem icon={<i className="fas fa-sign-out-alt"/>} onClick={userLogout}>
                      Log Out
                    </MenuItem>
                  </>
              )}
            </Menu>
          </SidebarContent>
          {process.env.REACT_APP_PORTAL_VERSION && stats?.apiVersion && (
              <SidebarFooter className="text-center">
                <p className="mt-3">
                  Portal {process.env.REACT_APP_PORTAL_VERSION} / API {stats.apiVersion}
                </p>
              </SidebarFooter>
          )}
        </ProSidebar>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
