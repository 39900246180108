import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CreateConsultantBroadcastForm from '../../components/forms/ConsultantTicket/CreateConsultantBroadcastForm';

class ConsultantBroadcastCreate extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Body>
                <Row>
                  <Col>
                    <CreateConsultantBroadcastForm />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConsultantBroadcastCreate;
